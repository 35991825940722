




































import { ref as _ref } from "@vue/composition-api";
import { defaultListQuery } from "@/select2/api/base";
import Api, { Params, ContextData } from "@/select2/context";
import Pagination from "@/components/Pagination/index.vue";
import { Lookup } from "@/select2/base";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const isAdmin = User.isAdmin;

  let count = _ref(0);

  let listQuery = _ref(({ ...defaultListQuery
  } as Params));

  let contexts = _ref(([] as ContextData[]));

  let loading = _ref(true);

  function names(values: Lookup[]) {
    let s = values.map(v => v.name).join(", ");

    if (s.length > 100) {
      s = s.substring(0, 97).concat("...");
    }

    return s;
  }

  async function load() {
    const data = await Api.list(listQuery.value);
    contexts.value = data.results;
    count.value = data.count;
    loading.value = false;
  }

  async function search() {
    listQuery.value.page = 1;
    load();
  }

  load();
  return {
    isAdmin,
    count,
    listQuery,
    contexts,
    loading,
    names,
    load,
    search
  };
};

__sfc_main.components = Object.assign({
  Pagination
}, __sfc_main.components);
export default __sfc_main;
