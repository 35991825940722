











import { ContextData } from "@/select2/context";
interface Props {
  context: ContextData;
}
const __sfc_main = {};
__sfc_main.props = {
  context: null
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};

export default __sfc_main;
