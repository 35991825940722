import { ListParams } from "./api/base"
import Generate from "./api/crud"
import { Lookup } from "./base"

export type MatchType = "EXACT" | "SEMANTIC_EXACT" | "SEMANTIC_BROAD" | "BROAD"

export function toMatchTypeString(n: number) {
	return new Map([
		[1, "EXACT"],
		[2, "SEMANTIC_EXACT"],
		[3, "SEMANTIC_BROAD"],
		[4, "BROAD"],
	]).get(n)
}

interface Topic {
	name: string
	keywords: string[]
}

export interface TopicData {
	matchType: MatchType
	topics: Topic[]
}
export interface Context {
	id?: number
	name: string
	is_active: boolean
	keywords: string[]
	simple_keyword_match: boolean
	topics_discovery: number
	match_type: number
	topics: Topic[]
	agencies: (Lookup | number)[]
	inventories: (Lookup | number)[]
	agreements: (Lookup | number)[]
}

export interface ContextData extends Context {
	id: number
	agencies: Lookup[]
	inventories: Lookup[]
	agreements: Lookup[]
}

export const defaultContextData: Context = {
	name: "",
	is_active: true,
	keywords: [],
	simple_keyword_match: false,
	topics_discovery: 5,
	match_type: 1,
	topics: [],
	agencies: [],
	inventories: [],
	agreements: [],
}

export interface Params extends ListParams {
	name?: string
}

export default Generate<ContextData, ContextData>("/contexts")
