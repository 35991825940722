






















































import { ref as _ref, computed as _computed } from "@vue/composition-api";
import router from "@/router";
import { Context } from "@/select2/context";
import Agencies from "@/select2/agency";
import Inventories from "@/select2/inventory";
import { Lookup, TransferData } from "@/select2/base";
import Agreements from "@/select2/agreement";
interface Props {
  value: Context;
  originalAgreements: Lookup[];
}
interface Emits {
  (e: "input", value: Context): void;
}
const __sfc_main = {};
__sfc_main.props = {
  value: null,
  originalAgreements: {
    key: "originalAgreements",
    required: true,
    type: Array
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const routeId = Number(router.currentRoute.params.id);
  const isEdit = !isNaN(routeId);
  const props = __props;
  const isAdmin = User.isAdmin;

  let agencies: TransferData[] = _ref([]);

  let agreements: TransferData[] = _ref([]);

  let inventories: TransferData[] = _ref([]);

  const contextRef = toRef(props, "value");
  const emit = __ctx.emit;
  watch(contextRef, value => {
    emit("input", value);
  }, {
    deep: true
  });
  onMounted(() => {
    load();

    if (isEdit) {
      reloadAgreements();
    }
  });

  let missingAgreements = _computed(() => props.originalAgreements.filter(a => props.value.agreements.includes(a.id) && !agreements.value.map(a => a.key).includes(a.id)));

  async function load() {
    const params = {
      lookup: "1"
    };
    const allAgencies = await Agencies.listAction<Lookup[]>("", params);
    agencies.value = allAgencies.map(a => ({
      key: a.id,
      label: a.name
    }));
    const allInventories = await Inventories.listAction<Lookup[]>("", params);
    inventories.value = allInventories.map(a => ({
      key: a.id,
      label: a.name
    }));
  }

  async function reloadAgreements() {
    if (props.value.agencies.length === 0 && props.value.inventories.length === 0) return;
    const params = {
      lookup: "1",
      agency: props.value.agencies.map(a => a.toString()),
      inventory: props.value.inventories.map(i => i.toString()),
      status: "3"
    };
    const data = await Agreements.listAction<Lookup[]>("", params);
    agreements.value = data.map(a => ({
      key: a.id,
      label: a.name
    }));
  }

  return {
    isAdmin,
    agencies,
    agreements,
    inventories,
    missingAgreements,
    reloadAgreements
  };
};

export default __sfc_main;
