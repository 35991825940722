











import { computed as _computed } from "@vue/composition-api";
import { Context, ContextData } from "@/select2/context";
import AvailabilityEditor from "./AvailabilityEditor.vue";
import AvailabilityViewer from "./AvailabilityViewer.vue";
import { Lookup } from "@/select2/base";
interface Props {
  value: Context;
  originalAgreements: Lookup[];
}
const __sfc_main = {};
__sfc_main.props = {
  value: null,
  originalAgreements: {
    key: "originalAgreements",
    required: true,
    type: Array
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const isAdmin = User.isAdmin;

  let d = _computed(() => (props.value as ContextData));

  return {
    isAdmin,
    d
  };
};

__sfc_main.components = Object.assign({
  AvailabilityEditor,
  AvailabilityViewer
}, __sfc_main.components);
export default __sfc_main;
