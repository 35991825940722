






























import { ref as _ref } from "@vue/composition-api";
import { Context, toMatchTypeString, TopicData } from "@/select2/context";
import { Message } from "element-ui";
import request from "@/select2/api/base";
interface Props {
  value: Context;
}
const __sfc_main = {};
__sfc_main.props = {
  value: null
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const valueRef = toRef(props, "value");
  const emit = __ctx.emit;
  watch(valueRef, newValue => {
    emit("input", newValue);
  }, {
    deep: true
  });

  let keywords = _ref("");

  let grouping = _ref(false);

  async function groupKeywords() {
    grouping.value = true;

    try {
      const data = {
        value: {
          topics: [{
            name: "keywords",
            keywords: keywords.value.split("\n")
          }],
          topicsNumber: props.value.topics_discovery,
          matchType: toMatchTypeString(props.value.match_type)
        }
      };
      const topicData = await request.post<TopicData, TopicData>("dsp_reallocate", data);
      props.value.topics = topicData.topics;
    } catch (err) {
      Message.error("Error");
    } finally {
      grouping.value = false;
    }
  }

  return {
    keywords,
    grouping,
    groupKeywords
  };
};

export default __sfc_main;
