




































import { ref as _ref, computed as _computed } from "@vue/composition-api";
import router from "@/router";
import Contexts, { ContextData, defaultContextData } from "@/select2/context";
import { Message } from "element-ui";
import { handleError } from "@/select2/api/base";
import Availability from "./components/Availability.vue";
import Topics from "./components/Topics.vue";
import { Lookup } from "@/select2/base";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const isAdmin = User.isAdmin;

  let currentTab = _ref("0");

  let loading = _ref(true);

  let saving = _ref(false);

  const routeId = Number(router.currentRoute.params.id);
  const isEdit = !isNaN(routeId);

  let context = _ref(defaultContextData);

  let originalAgreements: Lookup[] = _ref([]);

  onMounted(() => {
    if (isEdit) {
      load();
    } else {
      loading.value = false;
    }
  });

  let ready = _computed(() => !isEdit || !loading.value);

  async function load() {
    loading.value = true;
    const data = await Contexts.get(routeId);

    if (isAdmin) {
      context.value = { ...data,
        agencies: data.agencies.map(a => a.id),
        inventories: data.inventories.map(a => a.id),
        agreements: data.agreements.map(a => a.id)
      };
      originalAgreements.value = data.agreements;
    } else {
      context.value = data;
    }

    loading.value = false;
  }

  function saveCommand(cmd) {
    if (cmd === "save") save(false);
  }

  async function save(close: boolean) {
    saving.value = true;

    let call = async (body, _) => await Contexts.create(body);

    if (isEdit) call = async (body, id) => await Contexts.update(id, body);
    const data = (context.value as ContextData);
    const body = { ...omit(["id"], data)
    };

    try {
      await call(body, routeId);
      Message.success(`'${body.name}' saved`);

      if (close) {
        router.push("/contexts");
      }
    } catch (e) {
      handleError(e);
    } finally {
      saving.value = false;
    }
  }

  return {
    router,
    isAdmin,
    currentTab,
    saving,
    context,
    originalAgreements,
    ready,
    saveCommand,
    save
  };
};

__sfc_main.components = Object.assign({
  Availability,
  Topics
}, __sfc_main.components);
export default __sfc_main;
